import { Injectable } from '@angular/core'

import { Store, StoreConfig } from '@datorama/akita'
import { Query } from '@datorama/akita'

import { ProfileDto } from '@api/common/dto'
import { RoleNames } from '../dto/profile.dto'

export interface AuthState {
  sessionToken: string
  user: ProfileDto,
  isAdmin: boolean
  roles: RoleNames[]
}

export function createInitialState(): AuthState {
  return {
    sessionToken: '',
    user: null,
    isAdmin: false,
    roles: []
  }
}

@Injectable()
@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialState())
  }
}

@Injectable()
export class AuthQuery extends Query<AuthState> {
  isLoggedIn$ = this.select(state => !!state.sessionToken)
  authUser$ = this.select(state => state.user)
  isAdmin$ = this.select(state => state.isAdmin)
  
  constructor(protected store: AuthStore) {
    super(store);
  }
}
