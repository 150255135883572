import { Injectable, ElementRef } from '@angular/core'

@Injectable()
export class WindowRef {
  public safeOpen(url: string, target: string = "_tab", download?: string) {
    let a = document.createElement('a');

    a.href = url;
    a.target = target;

    if (download) {
      a.download = download;
    }

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
