import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { AuthStore } from '@api/common/store/auth'
import { ProfileDto, UserDto } from '@api/common/dto'

import { HttpService } from './http.service'

@Injectable()
export class AuthService {
  constructor(
    private httpService: HttpService,
    private authStore: AuthStore) {
  }

  async isAdmin() {
    const isAdmin = await this.httpService.get<boolean>('/admin/v1/is-admin')

    this.authStore.update({ isAdmin })
  }

  async isEmployer() {
    return await this.httpService.get<boolean>('/employer/v1/is-employer')
  }

  async current() {
    const user = await this.httpService.get<ProfileDto>('/v1/profile')
    const authUser = _.clone(user)
    
    this.authStore.update({ user: authUser, roles: authUser.roleNames })

    return user
  }

  async updatePassword(currentPassword: string, newPassword: string) {
    return await this.httpService.patch<boolean>('/auth/v1/update-password', {
      currentPassword,
      newPassword
    })
  }
  
  async sendEmailVerification(email: string) {
    return await this.httpService.post<boolean>('/auth/v1/email-verification', { email })
  }

  async forgetPassword(email: string) {
    return await this.httpService.post<boolean>('/auth/v1/forget-password', { email })
  }

  async getResetPassword(token: string) {
    return await this.httpService.get<UserDto>('/v1/reset-password/' + token)
  }

  async resetPassword(token: string, password: string) {
    return await this.httpService.patch<boolean>('/auth/v1/reset-password', {token, newPassword: password})
  }

  async logIn(email: string, password: string, shouldRemember: boolean) {
    const { sessionToken, user } = await this.httpService.post('/auth/v1/login', {email, password})

    await this.httpService.setSessionToken(sessionToken, shouldRemember)

    return user
  }

  async logOut() {
    await this.httpService.delete('/auth/v1/logout')

    return await this.httpService.removeSessionToken()
  }
}
