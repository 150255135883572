import { Injectable } from '@angular/core'

import * as _ from 'lodash'
import { AdminMajorDto, AdminFindMajorDto } from '../../admin/dto'
import { JobAttributeDto, JobCategoryDto, JobInformationDto, PageResultDto } from '../dto'
import { PagingDto } from '../dto/paging.dto'

import { HttpService } from './http.service'

@Injectable()
export class JobAttributeService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async findCategory({ limit, offset }: PagingDto) {
    return await this.httpService.get<PageResultDto<JobCategoryDto>>('/v1/category', { limit, offset })
  }

  async findMajor(findMajorDto: AdminFindMajorDto) {
    return await this.httpService.get<PageResultDto<AdminMajorDto>>('/v1/major', findMajorDto)
  }

  async find() {
    return await this.httpService.get<JobInformationDto>('/employer/v1/job-information')
  }

  async findJobLocation() {
    return await this.httpService.get<JobAttributeDto[]>('/v1/job-locations')
  }
}
